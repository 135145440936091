.certificate-images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 
  }
  
  .certificate-images img {
    width: 300px; 
    height: auto; 
    margin: 20px; 
  }
  

  @media screen and (max-width: 768px) {
    .certificate-images {
      flex-direction: column; 
      align-items: center; 
    }
  
    .certificate-images img {
      width: 100%; 
      margin: 10px 0; 
    }
  }
  