.form-container {
  width: 100%;
}

.form-container form {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 45%;
  margin: auto;
}

.form-container input,
.form-container textarea {
  height: 2rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  border: 1px solid #2a2a2a;
}

.form-container textarea {
  height: 5rem; 
  padding: 1rem;
}

.form-container button {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 0.3rem;
  border: 1px solid #2a2a2a;
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  .form-container {
    margin: 4rem 2rem;
  }

  .form-container form {
    padding-top: 2rem;
    width: 90%;
  }

  .form-status.success {
    background-color: #7FFF00; 
    color: #2a2a2a;
    font-weight: bold; 
    font-size: 1.2em; 
  }
  
  
  .form-status.error {
    background-color: #FF4500;
    color: #fff;
  }
}
