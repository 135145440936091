.about-cointainer {
  margin: 4rem 6rem;
  text-align: start;
}

.about-cointainer p {
  padding-bottom: 2rem;
}

@media screen and (max-width: 850px) {
  .about-cointainer {
    margin: 4rem 2rem;
  }
}
