.service-cointainer {
  margin: 4rem 6rem;
  text-align: start;
}

.service-cointainer h1 {
  padding-bottom: 3rem;
}

.service-cointainer h2 {
  padding-bottom: 1rem;
}

.service-cointainer p {
  padding-bottom: 2rem;
}

@media screen and (max-width: 850px) {
  .service-cointainer {
    margin: 4rem 2rem;
  }
}
