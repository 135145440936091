.footer {
  padding: 1rem 3rem;
  background-color: #000;
  color: #fff;
  width: 100%; 
  box-sizing: border-box; 
}


.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}


.footer-title {
  text-align: start;
}


.info {
  font-size: small;
  text-align: end;
}

@media (max-width: 850px) {
  .footer {
    padding: 1rem 2rem; 
  }

  .footer-title, .info {
    text-align: center; 
  }
}

